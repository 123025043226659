<script>
import KomponenAktivitasTag from "@/apps/aktivitas/components/KomponenAktivitasTag.vue";

export default {
  name: "KomponenPerawatanTag",
  extends: KomponenAktivitasTag,
  props: {
    kompId: String,
    jenis: String
  },
  data() {
    return {
      // routerParams: { kompId: this.kompId }
      routerTo: { name: this.namedUrl, params: { id: this.id, jenis: this.jenis, kompId: this.kompId } }
    };
  }
};
</script>